/**
 * This file defines a list of general constants to be used on project.
 */

export const defaults = {
  water: {
    analysis: "surface",
    territory: { code: 0, name: "Brasil", type: "country" },
    grouping: "biome",
    initiative: "brazil",
    viewport: {
      latitude: -13.923404,
      longitude: -35.449984,
      zoom: 3,
    },
    timeMode: "annual",
    initialTime: "1985", // [year]
    endTime: "2023", // [year]
  },
  glacier: {
    analysis: "surface",
    territory: { code: 2, name: "Países Amazônicos", type: "initiative" },
    grouping: "countryPanAmazon",
    initiative: "amazonCountries",
    viewport: {
      latitude: -13.923404,
      longitude: -35.449984,
      zoom: 3,
    },
    timeMode: "annual",
    initialTime: "1985", // [year]
    endTime: "2022", // [year]
  },
};

export const waterAnalysisOptions = {
  surface: {
    key: "surface",
    translation: "dashboard.filters.analysis.options.waterSurface",
    apiName: "surface",
    time: "both",
  },
  transition: {
    key: "transition",
    translation: "dashboard.filters.analysis.options.transition",
    apiName: "transitions",
    time: "annual",
  },
  trend: {
    key: "trend",
    translation: "dashboard.filters.analysis.options.trend",
    apiName: "trend",
    time: "annual",
  },
  waterClassification: {
    key: "waterClassification",
    translation:
      "dashboard.filters.analysis.options.waterSurfaceClassification",
    apiName: "classification",
    time: "annual",
  },
};

export const glacierAnalysisOptions = {
  surface: {
    key: "surface",
    translation: "dashboard.filters.analysis.options.glacierSurface",
    apiName: "surface",
    time: "annual",
  },
  transition: {
    key: "transition",
    translation: "dashboard.filters.analysis.options.transition",
    apiName: "transitions",
    time: "annual",
  },
};

export const options = {
  water: {
    analysis: {
      brazil: {
        ...waterAnalysisOptions,
      },
      ecuador: {
        ...waterAnalysisOptions,
      },
      colombia: {
        ...waterAnalysisOptions,
      },
      raisg: {
        ...waterAnalysisOptions,
      },
      venezuela: {
        ...waterAnalysisOptions,
      },
      peru: {
        ...waterAnalysisOptions,
      },
      bolivia: {
        ...waterAnalysisOptions,
      },
      /* suriname: {
        ...waterAnalysisOptions,
      },
      guyana: {
        ...waterAnalysisOptions,
      },
      frenchGuyana: {
        ...waterAnalysisOptions,
      }, */
      rest: {
        surface: {
          ...waterAnalysisOptions.surface,
        },
        transition: {
          ...waterAnalysisOptions.transition,
        },
        trend: {
          ...waterAnalysisOptions.trend,
        },
      },
    },
  },
  glacier: {
    analysis: {
      rest: {
        surface: {
          ...glacierAnalysisOptions.surface,
        },
        transition: {
          ...glacierAnalysisOptions.transition,
        },
      },
    },
  },
};

export const baseMaps = {
  analysis: "water",
  territory: { code: 1, name: "América do Sul", type: "continent" },
  grouping: "state",
};
